<template>
  <div class="works">
    <LeftMenu current="songManage"></LeftMenu>
    <div class="main" :class="{ updateApplyList: $route.meta.active }">
      <div class="manage">
        <div class="content">
          <div class="backBox">
            <el-breadcrumb separator="/">
              <el-breadcrumb-item to="/applyList">发行管理</el-breadcrumb-item>
              <el-breadcrumb-item>歌曲文件上传记录</el-breadcrumb-item>
            </el-breadcrumb>
            <el-link type="primary" @click="$router.back()">返回</el-link>
          </div>
          <el-divider></el-divider>
          <div class="nav-search">
            <el-date-picker
                v-model="form.created_at"
                value-format="yyyy-MM-dd HH:mm:ss"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
            </el-date-picker>
            <el-input v-model="form.song_name" style="width: 300px;" placeholder="请输入歌曲名关键字"></el-input>
            <el-button size="medium" style="margin-right: 10px;" @click="reset();getList();">重置</el-button>
            <el-button size="medium" type="primary" @click="form.page = 1;form.pageSize = 10; getList()">筛选</el-button>
          </div>
            <div>
              <el-table  v-loading="tableLoad"  :data="list" style="margin-top: 40px;" header-row-class-name="table-header">

                <el-table-column width="80" content label="序号" align="center">
                  <template v-slot="{ row, column, $index }">
                    {{($index + 1) + (form.page - 1) * form.pageSize}}
                  </template>
                </el-table-column>
                <el-table-column width="181" label="歌曲名称" prop="song_name"></el-table-column>
                <el-table-column width="136" label="歌曲版本">
                  <template v-slot="{row}">
                    {{row.version_note ? row.version_note : '/' }}
                  </template>
                </el-table-column>
                <el-table-column width="177" label="艺人名称" prop="singer"></el-table-column>
                <el-table-column width="222" label="创建时间" prop="created_at"></el-table-column>
                <el-table-column width="139">
                  <template v-slot:header>
                    <el-dropdown  @command="setSate">
                <span class="el-dropdown-link">
                  状态<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="">全部 <img v-if="form.status === ''" style="width: 10px;height: 10px; margin-left: 100px" src="../../assets/state-song-icon.png" alt=""></el-dropdown-item>
                        <el-dropdown-item :command="0">未上传 <img v-if="form.status === 0" style="width: 10px;height: 10px;margin-left: 100px" src="../../assets/state-song-icon.png" alt=""></el-dropdown-item>
                        <el-dropdown-item :command="1">已上传 <img v-if="form.status === 1" style="width: 10px;height: 10px;margin-left: 100px" src="../../assets/state-song-icon.png" alt=""></el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </template>
                  <template v-slot="{row}">
                    {{row.status ? '已上传' : '未上传'}}
                  </template>
                </el-table-column>
                <el-table-column label="操作" >
                  <template v-slot="{row}">
                    <share-link :id="row.id">
                      分享链接
                    </share-link>
                    <router-link target="_blank"  :to="'/share-musician/' + row.id">
                      <el-button style="margin: 0 20px;"  type="text">
                        上传
                      </el-button>
                    </router-link>
                    <el-button type="text" style="color:#999999;" @click="$router.push('/songFileDetails/' + row.id)">
                      查看
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          <el-pagination
              hide-on-single-page
              style="margin-top: 30px;display: flex;align-items: center;justify-content: center;"
              background
              @current-change="getList"
              :page-size="form.pageSize"
              :current-page.sync="form.page"
              layout="prev, pager, next, total, slot"
              :total="total">
             <div style="display: inline-block;">
               <div style="display: inline-block;"><label style="font-size: 13px;font-family: PingFang SC, PingFang SC-Regular;font-weight: 400;text-align: CENTER;color: #666666;">到第</label><el-input v-model="form.page" class="pagination-input"></el-input></div>
               <el-button @click="getList" style="width: 48px;height: 30px;background: #ffffff;border: 0.9px solid #e1e0e0;border-radius: 2px;">确定</el-button>
             </div>
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LeftMenu from "../../components/LeftMenu";
import shareLink from "@/views/issue/components/shareLink/index.vue"
export default {
  name: "songUploadRecord",
  components: {LeftMenu, shareLink},
  props:{
    type:[String]
  },
  data(){
    return{
      list:[],
     form:{
       page:1,
       pageSize:10,
       status:''
     },
      total:0,
      flag:true,
      tableLoad:false,
    }
  },
  mounted() {
    if(this.type !== undefined) this.form.status = Number(this.type);
    this.getList()
  },
  methods:{
    reset(){
       this.form = {
         page:1,
         pageSize:10,
         status:''
       }
    },
    getList(){
      this.tableLoad = true;
     this.$get(`/api/property/universal/track/list?status=${this.form.status}&page=${this.form.page}&pageSize=${this.form.pageSize}&song_name=${this.form.song_name || ''}&created_at=${this.form.created_at?.join(' - ') || ''}`).then(r => {
       this.list = r.data.list;
       this.total = r.data.total;
       this.tableLoad = false;
     })
    },
    setSate(val = ''){
      this.form.page = 1;
      this.form.status =val;
      this.getList()
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../styles/works";
.content {
  box-sizing: border-box;
  background-color: #ffffff;
  width: 100%;
  min-height: 700px;
  .backBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
 ::v-deep .el-menu{
   background: #f8f8f8;
   border: none;
   .el-submenu__title{
     border: none;
     &:hover{
     background: none;
   }
   }
 }
  .formBox {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .btn {
      width: 96px;
      height: 40px;
      border-radius: 2px;
      font-size: 14px;
    }


    .datePickerBox {
      height: 40px;
      width: 386px;
      margin-right: 26px;

      ::v-deep .el-input__inner {
        font-family:PingFang SC;
        height: 40px;
        background: #ffffff;
        border: 1px solid #e5e5e5;
        border-radius: 3px;
        padding: 0 10px;
        width: 384px;
      }
    }

    .selectBox {
      margin-right: 26px;
      ::v-deep .el-select {
        width: 220px;
      }

    }

    .inputBox {
      margin-right: 30px;
      ::v-deep .el-input__inner {
        width: 230px;
        padding: 0 30px;
      }
    }
  }
  .nav-search {
    ::v-deep .el-input__inner {
      &::placeholder{
        font-family:PingFang SC;
      }
    }
    @mixin rh {
      height: 40px;
      border-radius: 3px;
    }
    margin-top: 20px;
    display: flex;
    align-items: center;
    > div {
      margin-right: 24px;
      @include rh

    }
    ::v-deep .el-input {
      .el-input__inner{
        @include rh
      }
    }
    .el-button{
      width: 96px;
      @include rh
    }
  }
  ::v-deep .table-header{

    .el-table__cell{
      background:#f7f7f7;
      font-size: 14px;
      font-family: PingFang SC-Regular;
      font-weight: 400;
      color: #333333;
      font-family: PingFangSC-Semibold, PingFang SC,system-ui;
    }
  }
  .pagination-input{
    font-family: PingFangSC-Semibold, PingFang SC,system-ui;
    width: 30px;
    height: 30px;
    margin:0 17px;
   ::v-deep .el-input__inner{
     font-family: PingFangSC-Semibold, PingFang SC,system-ui;
      width: 30px;
      height: 30px;
      padding: 0;
    }
  }
}
</style>
